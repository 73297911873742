<template>
	<div>
		<s-crud
			title="Colores por Cultivo"
			:config="config"
			:filter="filter"
			add
			@save="save($event)"
			edit
			remove
		>
			<template v-slot="props">
				<v-container>
					<v-row>
						<v-col cols="3" lg="3" md="6" sm="12">
							<s-select-definition
								label="Tipo Cultivo"
								v-model="props.item.TypeCultive"
								:def="1173"
							></s-select-definition>
						</v-col>
						<v-col cols="3" lg="3" md="6" sm="12">
							<s-text
								label="Color"
								v-model="props.item.CbcName"
								ref="textCbcName"
							></s-text>
						</v-col>
						<v-col cols="3" lg="3" md="6" sm="12">
							<s-text
								label="Color Hexadecimal"
								v-model="props.item.CbcColor"
								ref="textCbcColor"
							></s-text>
						</v-col>
						<v-col cols="3" lg="3" md="6" sm="12">
							<s-text
								decimal
								label="Valor"
								v-model="props.item.CbcValue"
								ref="textCbcValue">

							</s-text>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template v-slot:filter>
				<v-container>
					<v-row>
						<v-col>
							<s-select-definition
								label="Tipo Cultivo"
								v-model="filter.TypeCultive"
								:def="1173"
							></s-select-definition>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</s-crud>
	</div>
</template>

<script>
	import _sCultiveByColorService from "@/services/FrozenProduction/CultiveByColorService.js"

	export default {
		data() {
			return {
				config: {
					model: {
						CbcID: "ID"
					},
					service: _sCultiveByColorService,
					headers: [
						{text: "ID", value: "CbcID"},
						{text: "Tipo de Cultivo", value: "TypeCultiveName"},
						{text: "Color", value: "CbcName"},
						{text: "Hexadecimal", value: "CbcColor"},
						{text: "Valor", value: "CbcValue"},
						
					]
				},
				filter: { TypeCultive: 0}
			}
		},

		methods: {
			save(item) {
				if(item.CbcName == "")
				{
					this.$fun.alert("Ingrese el nombre", "warning");
					this.$refs.focus("textDfcName");
					return;
				}

				item.save();
			}
		},
	}
</script>
